export default [
  {
    header: 'Adminisration',
  },
  {
    title: 'Administration',
    icon: 'BoxIcon',
    /*    tag: '2',
       tagVariant: 'light-warning', */
    children: [
      {
        title: 'Search',
        icon: 'SearchIcon',
        route: 'search-page',
      },
      {
        title: 'Reports',
        icon: 'AlertCircleIcon',
        children: [
          {
            title: 'Reported users',
            route: 'reports-users',
          },
          {
            title: 'Reported recipes',
            route: 'reports-recipes',
          },
          {
            title: 'Reported comments and replies',
            route: 'reports-comments',
          },
          {
            title: 'Reported chats',
            route: 'reports-chats',
          }
        ],
      },
      {
        title: 'Crashes',
        icon: 'AlertTriangleIcon',
        route: 'crashes-page',
      },
      {
        title: 'Contacts',
        icon: 'MailIcon',
        route: 'contacts-page',
      },
      {
        title: 'Push notification',
        icon: 'BellIcon',
        route: 'push-notification-page',
      },
      
    ],
  },
]
