export default [
    {
        header: 'Settings',
    },
    {
        title: 'Settings',
        icon: 'SettingsIcon',
        /*    tag: '2',
           tagVariant: 'light-warning', */
        children: [
            {
                title: 'Settings',
                icon: 'SettingsIcon',
                route: 'settings-page',
            },
            {
                title: 'Logout',
                icon: 'LogInIcon',
                route: 'logout',
            },
        ],
    },
]
